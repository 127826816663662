<template>
  <div class="application-clone" :class="{skeleton: !state.archive.loaded}">
    <div class="header">
      <div class="top">
        <div class="wrapper">
          <div class="core">
            <b class="title">신청서 복제</b>
            <div class="desc">신청서 양식을 선택 후 [복제하기] 버튼을 클릭해주세요.</div>
          </div>
          <div class="side">
            <select class="form-control" v-model="state.item.next.archiveId" @change="onNextArchiveIdChange()">
              <option :value="null">신청서 양식 목록</option>
              <option v-for="f in state.archive.list" :value="f.id" :key="f.id" :disabled="f.id === state.item.prev.archiveId ">{{ f.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="items">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th colspan="3">{{ state.item.prev.archiveTitle }}</th>
            <th rowspan="2">
              <div>복제</div>
              <div>가능</div>
            </th>
            <th colspan="3">{{ state.item.next.archiveTitle }}</th>
          </tr>
          <tr>
            <th>항목 이름</th>
            <th>항목 타입</th>
            <th>답변</th>
            <th>항목 이름</th>
            <th>항목 타입</th>
            <th>답변</th>
          </tr>
          </thead>
          <tbody>
          <tr :data-prev-item-id="i.prevItemId" :data-next-item-id="i.nextItemId" :class="{ warn: state.item.next.loaded && state.item.next.archiveId !== null && !i.mapped }" v-for="i in state.item.list">
            <td>{{ i.prevItemTitle }}</td>
            <td>{{ getItemTitle(i.prevItemType) }}</td>
            <td>{{ i.prevApplicationAnswerTranslated }}</td>
            <td class="text-center">{{ i.mapped ? "O" : "X" }}</td>
            <template v-if="!state.item.next.loaded || state.item.next.archiveId === null || i.mapped">
              <td>{{ i.nextItemTitle }}</td>
              <td>{{ getItemTitle(i.nextItemType) }}</td>
              <td>{{ i.nextApplicationAnswerTranslated }}</td>
            </template>
            <td class="p-0" colspan="3" v-else>
              <div class="text-center" v-if="form.optionTypes.includes(i.itemType) || ['file'].includes(i.itemType)">연결 불가(수동 처리 필요)</div>
              <template v-else>
                <select class="form-control" @change="onItemMappingChange(i, $event)">
                  <option selected>연결할 항목 선택</option>
                  <option :value="i.itemId" v-for="i in getNextItems(i.prevStepTitle, i.prevItemType)">{{ i.itemTitle }}</option>
                </select>
              </template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="bottom">
      <div class="row">
        <div class="col init col-lg-2">
          <button class="btn btn-default btn-block" @click="init()">초기화</button>
        </div>
        <div class="col submit col-lg-10">
          <button class="btn btn-primary btn-block" @click="submit()">신청서 복제하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "../scripts/http";
import form from "../scripts/form";

function Component(initialize) {
  this.name = "modalArchiveApplicationClone";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xl"});
      init();
    });

    const state = reactive({
      archive: {
        list: [],
        loaded: false,
      },
      item: {
        list: [],
        prev: {
          applicationId: null,
          archiveId: null,
          archiveTitle: "",
          loaded: false,
        },
        next: {
          archiveId: null,
          archiveTitle: "",
          list: [],
          unmaps: [],
          loaded: false,
        }
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      if (!state.item.next.archiveId) {
        window.alert("신청서 양식을 선택해주세요.");
        return;
      } else if (!state.item.list.some(i => i.mapped)) {
        window.alert("복제 가능한 항목이 없습니다.");
        return;
      }

      store.commit("confirm", {
        message: "신청서를 복제하시겠습니까?",
        subMessage: `[${state.item.prev.archiveTitle}\n-> ${state.item.next.archiveTitle}]`,
        allow() {
          const args = {
            nextArchiveId: state.item.next.archiveId,
            nextAnswers: []
          };

          for (const item of state.item.list) {
            if (!item.mapped) {
              continue;
            }

            args.nextAnswers.push({
              value: item.nextApplicationAnswer,
              itemId: item.nextItemId,
            });
          }

          http.post(`/api/admin/archives/${state.item.prev.archiveId}/applications/${state.item.prev.applicationId}/clone`, args).then(() => {
            window.alert("신청서 복제가 완료되었습니다.");
            window.location.href = "/new-admin/archive/applications";
          });
        }
      });
    };

    const getItemTitle = (type) => {
      return form.item.types.find(t => t.name === type)?.title;
    };

    const getNextItems = (stepTitle, itemType) => {
      return state.item.next.list.filter(i => i.stepTitle === stepTitle && i.itemType === itemType);
    };

    const translateApplicationAnswer = (item) => {
      if (form.optionTypes.includes(item.itemType)) {
        return item.itemOptions[item.itemOptions.findIndex(o => o.id.toString() === item.applicationAnswer)]?.title;
      }

      return item.applicationAnswer;
    };

    const loadArchives = () => {
      state.archive.loaded = false;
      http.get(`/api/admin/archives`, {page: 0, size: 100}).then(({data}) => {
        state.archive.loaded = true;
        state.archive.list = data.body.content;
      });
    };

    const resetItemNextValue = () => {
      state.item.next.archiveTitle = "";
      state.item.next.list = [];
      state.item.next.unmaps = [];

      for (const item of state.item.list) {
        item.mapped = false;
        item.nextStepTitle = "";
        item.nextItemType = "";
        item.nextItemTitle = "";
        item.nextItemContent = "";
        item.nextApplicationAnswer = "";
        item.nextApplicationAnswerTranslated = "";
      }
    };

    const updateNextItem = (item, nextItem) => {
      item.nextStepId = nextItem.stepId;
      item.nextStepTitle = nextItem.stepTitle;
      item.nextItemId = nextItem.itemId;
      item.nextItemType = nextItem.itemType;
      item.nextItemTitle = nextItem.itemTitle;
      item.nextItemContent = nextItem.itemContent;
      item.nextItemOptions = nextItem.itemOptions;
      item.nextApplicationAnswer = item.prevApplicationAnswer;
      item.nextApplicationAnswerTranslated = item.prevApplicationAnswerTranslated;
    };

    const onItemMappingChange = (item, e) => {
      const itemId = Number.parseInt(e.target.value);
      const nextItem = state.item.next.list.find(i => i.itemId === itemId);

      item.mapped = true;
      updateNextItem(item, nextItem);
    };

    const onNextArchiveIdChange = () => {
      resetItemNextValue();

      if (!state.item.next.archiveId) {
        return;
      }

      state.item.next.archiveTitle = state.archive.list.find(f => f.id === state.item.next.archiveId)?.title;
      loadNextArchiveItems();
    };

    const loadNextArchiveItems = () => {
      state.item.next.loaded = false;
      http.get(`/api/admin/archives/${state.item.next.archiveId}/applications/null/clone`).then(({data}) => {
        state.item.next.loaded = true;
        state.item.next.list = data.body;

        for (const item of state.item.list) {
          for (const nextItem of state.item.next.list) {
            if (item.prevStepTitle === nextItem.stepTitle && item.prevItemType === nextItem.itemType && item.prevItemTitle === nextItem.itemTitle) {
              updateNextItem(item, nextItem);

              if (form.optionTypes.includes(item.prevItemType)) {
                const nextItemOption = item.nextItemOptions.find(i => i.title === item.prevApplicationAnswerTranslated);

                if (nextItemOption) {
                  item.nextApplicationAnswer = nextItemOption.id;
                  item.nextApplicationAnswerTranslated = nextItemOption.title;
                } else {
                  item.nextApplicationAnswer = "";
                  item.nextApplicationAnswerTranslated = "";
                }
              }

              item.mapped = item.prevApplicationAnswerTranslated === item.nextApplicationAnswerTranslated;
            }
          }
        }
      });
    };

    const loadPrevArchiveItems = () => {
      state.item.prev.loaded = false;
      http.get(`/api/admin/archives/${state.item.prev.archiveId}/applications/${state.item.prev.applicationId}/clone`).then(({data}) => {
        state.item.prev.loaded = true;
        state.item.list = [];

        for (const item of data.body) {
          state.item.list.push({
            prevStepId: item.stepId,
            prevStepTitle: item.stepTitle,
            prevItemId: item.itemId,
            prevItemType: item.itemType,
            prevItemTitle: item.itemTitle,
            prevItemContent: item.itemContent,
            prevItemOptions: item.itemOptions,
            prevApplicationAnswer: item.applicationAnswer,
            prevApplicationAnswerTranslated: translateApplicationAnswer(item),
            nextStepId: null,
            nextStepTitle: "",
            nextItemId: null,
            nextItemType: "",
            nextItemTitle: "",
            nextItemContent: "",
            nextItemOptions: [],
            nextApplicationAnswer: "",
            nextApplicationAnswerTranslated: "",
            mapped: false
          });
        }
      });
    };

    const init = () => {
      state.item.next.archiveId = null;
      state.item.prev.applicationId = modalParams.applicationId;
      state.item.prev.archiveId = modalParams.archiveId;
      state.item.prev.archiveTitle = modalParams.archiveTitle;

      resetItemNextValue();
      loadArchives();
      loadPrevArchiveItems();
    };

    return {component, state, form, getItemTitle, getNextItems, onItemMappingChange, onNextArchiveIdChange, init, submit};
  }
});
</script>

<style lang="scss" scoped>
.application-clone {
  background-color: #fff;
  min-height: $px300;
  padding: $px20 $px20 0 $px20;

  .header {
    .top {
      margin-bottom: $px21;

      > .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        > .core {
          .title {
            display: inline-block;
            font-size: $px18;

            b {
              font-weight: 600;
            }
          }

          .desc {
            font-size: $px14;
            margin-top: $px1;
            color: #666;
          }
        }
      }
    }
  }

  .content {
    .items {
      table {
        table-layout: fixed;

        thead tr th, tbody tr td {
          vertical-align: middle;
        }

        thead {
          tr {
            th {
              background: $colorBackground;
              font-size: $px14;
              vertical-align: middle;

              &[colspan] {
                width: calc((100% - $px60) / 2);
              }

              &[rowspan] {
                width: $px60;
                text-align: center;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              select {
                font-size: $px12;
                height: $px50;
                border-color: transparent;
                border-radius: 0;

                &:focus {
                  border-color: $colorPurple;
                }
              }
            }

            &.warn {
              background: #fff1f2;
            }
          }
        }
      }
    }
  }

  .bottom {
    background: #fff;
    position: sticky;
    bottom: 0;
    margin-top: $px15;
    padding-bottom: $px15;
    left: 0;
    width: 100%;

    .row {
      .col {
        .btn {
          padding: $px20;
        }

        &.init {
          padding-right: calc($px15 / 2);

          .btn {
            background: $colorLight;
          }
        }

        &.submit {
          padding-left: calc($px15 / 2);
        }
      }
    }
  }
}
</style>